//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import global from '~/mixins/global.js'
import projectData from '~/static/config/project.json'
import { openAuthRequired } from '~/lib/auth'
import AppLink from './AppLink.vue'
Vue.config.silent = true
export default {
  name: 'ModuleFinder',
  components: {
    VueTypeaheadBootstrap,
    AppLink
  },
  mixins: [global],
  inheritAttrs: false,
  props: {
    data: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    product: {
      type: [Object, Array],
      default: () => [{}, []]
    },
    zone: {
      type: String,
      default: ''
    },
    products: {
      type: [Object, Array],
      default: () => [{}, []]
    }
  },
  data () {
    return {
      suggestionsArray: [],
      projectID: projectData.id
    }
  },
  beforeMount () {
    if (!this.$store.state.articlesLoaded || !this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts').then(() => {
        this.$store.dispatch('loadArticles').then(() => {
          this.suggestionsArray = this.$store.state.products.map(p => {
            return {
              title: p.title,
              brand: p.brand,
              type_id: p.type_id,
              path: p.path,
              photo: p.photo
            }
          })
          const suggestionsArticles = this.$store.state.articles.map(a => {
            return {
              title: a.title,
              path: a.path,
              photo: a.photo
            }
          })

          this.suggestionsArray = this.mixSuggestions(this.suggestionsArray, suggestionsArticles)
        })
      })
    } else {
      this.suggestionsArray = this.$store.state.products.map(p => {
        return {
          title: p.title,
          brand: p.brand,
          type_id: p.type_id,
          path: p.path,
          photo: p.photo
        }
      })
      const suggestionsArticles = this.$store.state.articles.map(a => {
        return {
          title: a.title,
          path: a.path,
          photo: a.photo
        }
      })

      this.suggestionsArray = this.mixSuggestions(this.suggestionsArray, suggestionsArticles)
    }
  },
  mounted () {
    if (!this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts')
    }
  },
  methods: {
    evento (data) {
      const query = data.target?.value ? data.target.value : data.title
      const tipo = data.target?.value ? 'query' : 'sugerencia'
      this.$gtm && this.$gtm.push({ event: 'ClickFinder', query, origen: 'module', tipo })
    },
    mixSuggestions (array1, array2) {
      const resultado = []
      const maxLength = Math.max(array1.length, array2.length)

      for (let i = 0; i < maxLength; i++) {
        if (i < array1.length) {
          resultado.push(array1[i])
        }
        if (i < array2.length) {
          resultado.push(array2[i])
        }
      }
      return resultado
    },
    findItem (product) {
      if (product.brand) {
        const pathMatches = product.path.match(
          /^\/json\/products\/([0-9]+)(.+?)index.json$/
        )
        const prodTypeConfig = Object.values(projectData.contents.product_types).filter(
          p => p.id === product.type_id
        )
        const url = `/${prodTypeConfig[0].paths.home}${pathMatches[2]}?`
        this.$router.push(url)
      } else {
        const pathMatches = product.path.match(
          /^\/json\/articles\/(.+?)index.json$/
        )
        const url = `/${projectData.contents.articles.all.paths.home}/${pathMatches[1]}`
        this.$router.push(url)
      }
    },
    onCheckLogin () {
      if (this.$auth.loggedIn) {
        openAuthRequired.apply(this, [
          null,
          {
            name: 'showModal'
          },
          'modal',
          'forumChat'
        ])
      } else {
        openAuthRequired.apply(this, [
          null,
          {
            name: 'showModal'
          },
          'modal',
          'products'
        ])
      }
    }
  }
}
