//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
import Vue from 'vue'
import project from '~/static/config/project.json'
import global from '~/mixins/global.js'
Vue.config.silent = true
export default {
  components: {
    VueTypeaheadBootstrap
  },
  mixins: [global],
  props: {
    suggestions: {
      type: Object,
      default: () => { }
    },
    products: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      query: '',
      hideModal: false,
      productsBrands: [],
      productsArray: [],
      articlesArray: [],
      suggestionsArray: []
    }
  },
  beforeMount () {
    if (!this.$store.state.articlesLoaded || !this.$store.state.productsLoaded) {
      this.$store.dispatch('loadProducts').then(() => {
        this.$store.dispatch('loadArticles').then(() => {
          this.productsArray = this.$store.state.products.map(p => {
            return {
              title: p.brand + ' ' + p.title,
              type_id: p.type_id,
              path: p.path,
              photo: p.photo,
              type: 'product',
              search: this.plainText(p.brand + p.title)
            }
          })
          this.articlesArray = this.$store.state.articles.map(a => {
            return {
              title: a.title,
              path: a.path,
              photo: a.photo,
              type: 'article',
              search: this.plainText(a.title)
            }
          })
        })
      })
    } else {
      this.productsArray = this.$store.state.products.map(p => {
        return {
          title: p.brand + ' ' + p.title,
          type_id: p.type_id,
          path: p.path,
          photo: p.photo,
          type: 'product',
          search: this.plainText(p.brand + ' ' + p.title),
          prioritySearch: this.plainText(p.brand)
        }
      })
      this.articlesArray = this.$store.state.articles.map(a => {
        return {
          title: a.title,
          type_id: a.type_id,
          path: a.path,
          photo: a.photo,
          type: 'article',
          search: this.plainText(a.title)
        }
      })
    }

    this.suggestionsArray = [].concat(this.productsArray, this.articlesArray)
  },
  mounted () {
    this.setFocus()
  },
  methods: {
    setGTMCustomEvent (data) {
      const query = data.target?.value ? data.target.value : data.cards_enlace_texto ? data.cards_enlace_texto : data.lista_enlace_titulo ? data.lista_enlace_titulo : data.title
      const tipo = data.target?.value ? 'query' : data.cards_enlace_texto ? 'promocion-card' : data.lista_enlace_titulo ? 'promocion-link' : 'sugerencia'
      this.$gtm && this.$gtm.push({ event: 'ClickFinder', query, origen: 'finder', tipo })
    },
    mixSuggestions (array1, array2) {
      const resultado = []
      const maxLength = Math.max(array1.length, array2.length)

      for (let i = 0; i < maxLength; i++) {
        if (i < array1.length) {
          resultado.push(array1[i])
        }
        if (i < array2.length) {
          resultado.push(array2[i])
        }
      }
      return resultado
    },
    findItem (item) {
      if (item.type === 'product') {
        const pathMatches = item.path.match(
          /^\/json\/products\/([0-9]+)(.+?)index.json$/
        )
        const prodTypeConfig = Object.values(project.contents.product_types).find(p => p.id === item.type_id)
        if (prodTypeConfig && pathMatches) {
          const url = `/${prodTypeConfig.paths.home}${pathMatches[2]}`
          this.$router.push(url)
        }
      } else if (item.type_id === 15) {
        const pathMatches = item.path.match(
          /^\/json\/articles\/(.+?)index.json$/
        )
        const url = `/${project.contents.articles.all.paths.home}/${pathMatches[1]}`
        this.$router.push(url)
      } else if (item.type_id === 60) {
        const pathMatches = item.path.match(
          /^\/json\/galleries\/(.+?)index.json$/
        )
        const url = `/${project.contents.galleries.all.paths.home}/${pathMatches[1]}`
        this.$router.push(url)
      }
    },
    setFocus () {
      this.$refs.productfinder.$refs.input.focus()
    },
    closeFinder () {
      this.hideModal = true
      this.$emit('closeFinder')
    },
    taSearch () {
      if ((this.query.length > 1)) {
        const query = this.plainText(this.query)
        if (query) {
          const productsArray = [].concat(this.productsArray.filter(p => {
            return p.prioritySearch === query
          }), this.productsArray.filter(p => {
            return (p.prioritySearch !== query) && (p.search.indexOf(query) > -1)
          }))
          const articlesArray = this.articlesArray.filter(a => {
            return (a.search.indexOf(query) > -1)
          })
          this.suggestionsArray = [].concat(productsArray.slice(0, 8), articlesArray.slice(0, 20))
        } else {
          this.suggestionsArray = [].concat(this.productsArray, this.articlesArray)
        }
      }
    },
    plainText (text) {
      const from = 'ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;'
      const to = 'aaaaaeeeeeiiiiooooouuuunc------'
      from.split('').forEach((character, i) => {
        text = text.replace(new RegExp(character, 'g'), to.charAt(i))
      })
      return text
        .toString()
        .toLowerCase()
        .trim()
        .replace(/\s+/g, '-')
        .replace(/&/g, '-y-')
        .replace(/[^\w-]+/g, '')
        .replace(/--+/g, '-')
    }
  }
}
